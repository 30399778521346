import React from 'react';
import styled from 'styled-components';
import { sectionMargins } from '../styles';
import { Container, AnimatedWords } from './ui';
import { H1 } from './typography/Typography';

const StyledPageHeading = styled.section`
  ${sectionMargins('60px', '160px')};
`;

const StyledHeading = styled(H1)`
  margin: 0 auto;
  max-width: 1000px;
  text-align: center;
`;

const PageHeading = ({ heading }) => (
  <StyledPageHeading>
    <Container>
      <StyledHeading>
        <AnimatedWords>{heading}</AnimatedWords>
      </StyledHeading>
    </Container>
  </StyledPageHeading>
);

export default PageHeading;
