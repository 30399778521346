import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import PageHeading from '../components/PageHeading';
import ModularBlocks from '../components/ModularBlocks';

const DefaultPageTemplate = ({
  data: {
    datoCmsPage: {
      seoMetaTags,
      noIndex,
      title,
      modularBlocks,
      removeLinesBackground,
    },
  },
}) => {
  const distilleryTitle = 'Distillery'; //don't display title on distillery page
  return (
    <Layout
      removeLinesBackground={removeLinesBackground}
      seo={seoMetaTags}
      noIndex={noIndex}
      isHome={title === distilleryTitle} //with the property image overlaying navbar
    >
      <main>
        {title !== distilleryTitle && <PageHeading heading={title} />}

        <ModularBlocks items={modularBlocks} />
      </main>
    </Layout>
  );
};

export const DefaultPageTemplateQuery = graphql`
  query DefaultPageTemplateQuery($id: String!) {
    datoCmsPage(id: { eq: $id }) {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      noIndex
      title
      modularBlocks {
        ...AccordionModularBlockFragment
        ...AnchoredContentBlocksModularBlockFragment
        ...BrandyComingSoonModularBlockFragment
        ...CallOutCardsModularBlockFragment
        ...ContainedImageModularBlockFragment
        ...ContentCardsModularBlockFragment
        ...ContentModularBlockFragment
        ...ExternalVideoModularBlockFragment
        ...FeaturedAwardsModularBlockFragment
        ...FeaturedImagesModularBlockFragment
        ...FeaturedProductsModularBlockFragment
        ...FullWidthImageModularBlockFragment
        ...HighlightedImagesModularBlockFragment
        ...IconUspsModularBlockFragment
        ...ImageContentModularBlockFragment
        ...ImageGalleryModularBlockFragment
        ...ImageSliderModularBlockFragment
        ...ImageUspsModularBlockFragment
        ...InstagramFeedModularBlockFragment
        ...InternalVideoModularBlockFragment
        ...IntroContentModularBlockFragment
        ...PageBreakModularBlockV1Fragment
        ...PageBreakModularBlockV2Fragment
        ...StatementTextModularBlockFragment
        ...StoryContentModularBlockFragment
        ...TabsModularBlockFragment
        ...KlaviyoCtaModularBlockFragment
        ...BannerModularBlockFragment
        ...CtaWithImageModularBlockFragment
      }
      removeLinesBackground
    }
  }
`;

export default DefaultPageTemplate;
